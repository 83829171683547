<template>
  <span class="filter-it" :class="[activeIndex === index ? 'active' : '' ]" v-for="(item,index) in list" :key="index" @click="onClick(index)">{{item[bindLabel]}}</span>
</template>
<script>
//  mt="margin-top:39px;" dingwei="text-align:center"
// import { Overlay } from 'vant'
import { reactive, onMounted, toRefs, computed, watch,ref, toRef } from 'vue'
export default {
  components: {
  },
  emits: ['reset', 'submit'],
  props: {
    modelValue:[String, Number],
    bindKey:String,
    bindLabel:String,
    list:Array
  },
  setup(props, { emit }) {
    let state = reactive({
      activeIndex:''
    })
    const onClick = (index)=>{
      state.activeIndex = index
      let select = props.list.find((v,i) => i===index);
      if(select){
        emit('update:modelValue',select[props.bindKey]);
      }
    }
    const getActiveIndex = ()=>{
      if (props.list.length) {
        // 初始化默认选项
        state.activeIndex = props.list.findIndex(v => v[props.bindKey] === props.modelValue);
      }
    }
    watch(() => [props.modelValue],() => {
      getActiveIndex()
    })
    onMounted(()=>{
      getActiveIndex()
    })
    return {
      onClick,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
 .filter{

    &-it{
      background: #f8f8f8;
      height: 32px;
      line-height: 32px;
      min-width: 80px;
      text-align: center;
      margin: 5px;
      border-radius: 32px;
      padding: 0 10px;
      &.active{
        background: #DABA88;
      }
    }
  }
</style>
