import {post,get} from '../utils/axios'
// 粉丝数据
export function getFansStatistics(params) {
  return post('fans/getData', params)
}
// 粉丝筛选条件
export function getFansOptions(params) {
  return post('fans/getOptions', params)
}
// 粉丝列表
export function getFansData(params) {
  return post('fans/getDetailList', params)
}


