<template>
  <div class="filter-cell">
      <div class="filter-cell-title">{{title}}</div>
      <div class="filter-cell-body">
        <slot></slot>
      </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    title: String
  },
  setup(props, { emit }) {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
  .filter-cell{
    &-title{
      height: 32px;
      line-height: 32px;
      font-weight: bold;
    }
    &-body{
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>
