<template>
  <div class="popBox" v-if="show"  @click.self="onHide">
    <div class="filter" :class="show?'filter-in':'filter-out'">
            <div class="filter-head">筛选</div>
            <div class="filter-body">
              <slot></slot>
            </div>
            <div class="filter-footer">
              <button class="filter-footer-btn left" @click="onReset">重置</button>
              <button class="filter-footer-btn right"  @click="onSubmit">完成</button>
            </div>
        </div>
  </div>
</template>
<script>
//  mt="margin-top:39px;" dingwei="text-align:center"
// import { Overlay } from 'vant'

export default {
  components: {
  },
  emits: ['reset', 'submit','close'],
  props: {
    show: Boolean
  },
  setup(props, { emit }) {
    function onReset() {
      emit('reset')
    }
    function onSubmit() {
      emit('submit')
    }
    const onHide = ()=>{
      emit('update:show',false)
    }

    return {
      onReset,
      onSubmit,
      onHide
    }
  }
}
</script>
<style lang="less" scoped>
  .filter{
    transition: all 1s;
    transform: translateX(100%);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    overflow: hidden;
    float: right;
    right: 0;
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // padding-top: 44px;
    z-index: auto;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom,0)!important;
    padding-bottom: env(safe-area-inset-bottom,0)!important;
    &-head{
      padding: 0 15px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
    }
    &-body{
      padding: 20px;
      flex: 1;
      overflow-y: scroll;
      &::-webkit-scrollbar{
      //  background-color:transparent;
      display: none;
    }
    }
    &-footer{
      overflow: hidden;
      height: 50px;
      line-height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &-btn{
        flex: 1;
        &.left{
          background: #DABA88;
          color: #000;
        }
      }
    }
  }
  .popBox{
    transition: all 0.5s;
  }
  .popBox-block{
    display: block;
  }
  .popBox2{
    transition: all 0.5s time;
  }
  .filter-in{
    animation: filter-in .3s forwards;
  }
  .filter-out{
    animation: filter-out .3s forwards;
  }
  @keyframes filter-in{
    from{
      transform: translateX(100%);
    }
    to{
      transform: translateX(0);
    }
  }
  @keyframes filter-out{
    from{
      transform: translateX(0%);
    }
    to{
      transform: translateX(100%);
    }
  }
</style>
